:root {
  --account-insight-color: #2803d0;
  --industry-insight-color: #01579B;
  --asset-insight-color: #15b689;
  --navy-blue-color: #404762;
  --secondary-navy-blue-color: #5e6787;
  --border-gray-color: #b4b7b6;
  --dark-gray-color: #808080;
  --light-gray-color: #d9d9d9;
  --text-primary-color: #1c2c4b;
  --white-color: #ffffff;
  --fire-orange-color: #d06c07;
  --page-background-color: #f5f5f5;
  --alert-green-color: #2da44e;
  --alert-red-color: #d00758;
  --user-green-color: #d6f6cc;
  --highlight-light-blue-color: #f0f4f3;
} 