.error_container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: var(--page-background-color);
}

.error_contentColumn {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  position: relative;
}

i.fa-face-sad-sweat {
  font-size: 150px;
  color: var(--text-primary-color);
  padding-bottom: 30px;
}

i.fa-ban {
  font-size: 150px;
  color: var(--text-primary-color);
  padding-bottom: 30px;
}

.error_footerButton {
  width: 127px;
  height: 47px;
  background: var(--white-color);
  border-radius: 12px;
  border: 1px solid var(--navy-blue-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.error_footerButtonText {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--navy-blue-color);
}

.error_footerButton:hover {
  background: var(--navy-blue-color);
}

.error_footerButton:hover .error_footerButtonText {
  color: var(--white-color);
}

i.fa-house {
  color: var(--text-primary-color);
  font-size: 24px;
  padding-right: 10px;
}

.error_footerButton:hover i.fa-house {
  color: var(--white-color);
}

.error_title {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 30px;
  color: var(--navy-blue-color);
}

.error_description {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 26px;
  color: var(--navy-blue-color);
  padding-bottom: 20px;
  text-align: center;
}
